import React from 'react'
import {Link} from 'react-router-dom'


const BannerRetail = (props) => {
  return (
    <>
    <style>{`
        .c_title {
          font-size: 6rem!important;
          line-height: 4.5rem!important;
        }

        @media (max-width: 1100px) {
          .c_title {
            font-size: 3rem!important;
            line-height: 3rem!important;
          }
        @media (max-width: 460px) {
          .c_title {
            font-size: 2rem!important;
            line-height: 3rem!important;
          }
        }
      `}</style>

      <div className='retailBanner d-flex flex-column align-items-center gap-4 banner_img text-white d-flex align-items-center justify-content-center' style={{ backgroundImage: `url(${props.img})`, backgroundPosition: '75% center', backgroundSize: 'cover', position: 'relative'}}>
          <div className="banner_overlay"></div>
          <div className='banner_content w-75 text-center' style={{ top: `${props.pos}`, position: 'absolute'}}>
            <h1 className='text-white c_title'>{props.title}</h1>
            <p>{props.desc}</p>
          </div>
          <div style={{position: 'absolute', bottom: '20%'}}>
            <Link to={props.link} target='_blank' className="btn btn-primary btn-show text-white">Download Document</Link>
          </div>
        </div>
    </>
  )
}

export default BannerRetail